<template>
  <nav class="navbar navbar-expand-lg navbar-landing fixed-top" id="navbar">
    <div class="container">
      <a class="navbar-brand" href="/">
        <img src="@/assets/images/logo-dark.png" class="card-logo card-logo-dark" alt="logo dark"
             height="30">
        <img src="@/assets/images/logo-light.png" class="card-logo card-logo-light" alt="logo light"
             height="30">
      </a>
      <button class="navbar-toggler py-0 fs-20 text-body" type="button" data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
              aria-expanded="false" aria-label="Toggle navigation">
        <i class="mdi mdi-menu"></i>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mx-auto mt-2 mt-lg-0" id="navbar-example">
          <li class="nav-item">
            <a class="nav-link active" href="#about">{{ $t("t-menu-about") }}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#advantages">{{ $t("t-menu-advantages") }}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#features">{{ $t("t-menu-features") }}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#plans">{{ $t("t-menu-plans") }}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#faqs">{{ $t("t-menu-faqs") }}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#contact">{{ $t("t-menu-contact") }}</a>
          </li>
        </ul>

        <language-selector></language-selector>

        <div class="">
          <router-link v-if="loggedIn" to="/dashboard" class="btn btn-success" style="margin-right: 5px;">
            <i class="mdi mdi-login text-muted fs-16 align-middle me-1"></i>
            {{ $t("t-dashboard") }}
          </router-link>
          <router-link v-if="loggedIn" @click="logOut" to="#" class="btn btn-primary">
            <i class="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>
            {{ $t("t-logout") }}
          </router-link>
          <router-link v-if="!loggedIn" to="/login" class="btn btn-link fw-medium text-decoration-none text-dark">
            {{ $t("t-sign-in") }}
          </router-link>
          <router-link v-if="!loggedIn" to="/registration" class="btn btn-primary">
            {{ $t("t-sign-up") }}
          </router-link>
        </div>
      </div>

    </div>
  </nav>
</template>

<script>
import {authComputed, authMethods} from "@/state/helpers";
import LanguageSelector from "@/components/language-selector";

export default {
  name: 'NavBar',
  components: {LanguageSelector},
  computed: {
    ...authComputed,
  },
  methods: {
    ...authMethods
  },
  mounted() {
    window.addEventListener('scroll', function (ev) {
      ev.preventDefault();
      var navbar = document.getElementById("navbar");
      if (navbar) {
        if (document.body.scrollTop >= 50 || document.documentElement.scrollTop >= 50) {
          navbar.classList.add("is-sticky");
        } else {
          navbar.classList.remove("is-sticky");
        }
      }
    });
  },
}
</script>
