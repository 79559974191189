<script>
    import appConfig from "./../../app.config.json";
    import Footer from "../components/landing/Footer";
    import i18n from "../i18n";
    import {authComputed, localeComputed} from "@/state/helpers";
    import NavBar from "@/components/landing/NavBar";
    import DocPrivacyPolicyEn from "@/pages/doc-privacy-policy-en";

    export default {
        page: {
            title: "Starter",
            meta: [{
                name: "description",
                content: appConfig.description
            }],
        },
        data() {
            return {
                title: "Damask",
                lan: i18n.locale,
                items: [{
                        text: "Pages",
                        href: "/",
                    },
                    {
                        text: "Damask",
                        active: true,
                    },
                ],
            };
        },
        components: {
          DocPrivacyPolicyEn,
          NavBar,
          Footer,
        },
        methods: {
        },
        computed: {
          ...authComputed,
          ...localeComputed
        },
    };
</script>
<style>
ol {
  list-style-type: none;
  counter-reset: item;
  margin: 0;
  padding: 0;
}

ol > li {
  display: table;
  counter-increment: item;
  margin-bottom: 0.6em;
}

ol > li:before {
  content: counters(item, ".") ". ";
  display: table-cell;
  padding-right: 0.6em;
}

li ol > li {
  margin: 0;
}

li ol > li:before {
  content: counters(item, ".") " ";
}
</style>
<template>
    <div class="layout-wrapper landing">
      <NavBar></NavBar>
      <DocPrivacyPolicyEn></DocPrivacyPolicyEn>
      <!-- @TODO add translated components for all locales and use condition for each currentLocale === "[locale]" -->
<!--      <DocPrivacyPolicyEn v-if="currentLocale === 'en'"></DocPrivacyPolicyEn>-->
      <Footer></Footer>
    </div>
</template>
