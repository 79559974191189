<template>
  <section class="section pb-0 hero-section bg-white" id="doc-privacy-policy">
  <div class="bg-white bg-overlay-pattern"></div>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-8 col-sm-10">
        <div class="text-left mt-lg-5 pt-5">
          <h1 class="display-6 fw-semibold mb-3 lh-base">Privacy Policy</h1>
          <ul>
            <li><a href="#intro">Introduction</a></li>
            <li><a href="#terms-and-definitions">Terms and definitions</a></li>
            <li><a href="#the-composition-of-the-users-personal-data">The composition of the User's personal data</a></li>
            <li><a href="#confidentiality-of-personal-data">Confidentiality of personal data</a></li>
            <li><a href="#rights-and-responsibilities-of-the-operator">Rights and responsibilities of the Operator</a></li>
            <li><a href="#personal-data-user-rights">Personal data User rights</a></li>
            <li><a href="#personal-data-processing">Personal data processing</a></li>
            <li><a href="#transfer-of-personal-data">Transfer of personal data</a></li>
            <li><a href="#storage-of-personal-data">Storage of personal data</a></li>
            <li><a href="#protection-of-personal-data-of-users">Protection of personal data of Users</a></li>
            <li><a href="#responsibility-for-disclosing-information">Responsibility for disclosing information</a></li>
          </ul>
          <ol>
            <li>
              <h2 id="intro">Introduction</h2>
              <p>
                Our team takes your privacy seriously. Maintaining the confidentiality of your personal data is a core principle of our entire business operations. During the rendering of services, we gather, collect and possess certain private information/personal data of yours. This is why we would like to inform you about the following privacy policy that is applicable to you while receiving or applying for any of our services. Please note that this policy is only applicable to websites directly maintained by Damask and not to the other entities or persons to whom we might refer through banners or links within our websites.
              </p>
            </li>
            <li>
              <h2 id="terms-and-definitions">Terms and definitions</h2>
              <p>
                For the purposes of this Regulation, the following terms are used with the following meanings:
              </p>
              <ul>
                <li><b>Platform</b> - Internet site Damask Trading located on the Internet at: https://damask- trading.com/.</li>
                <li><b>User</b> - A private person who is involved in the functioning of the Platform or using the results of its functioning.</li>
                <li><b>Personal data</b> - any information relating to the Platform User.</li>
                <li><b>Processing of personal data</b> - actions (operations) with personal data, including collection, systematization, accumulation, storage, clarification (updating, changing), use, distribution (including transfer), depersonalization, blocking, destruction of personal data.</li>
                <li><b>Registration (signup)</b> - the process when you provide your data to the Platform in order to gain access to the full functionality of the system, whether it be additional features or access to resources that unauthorized Users cannot view or download.</li>
                <li><b>Authentication</b> - the process of verifying the User's data that were provided during Registration in order to open access to the closed sections of the Platform and to the additional functionality of the Platform.</li>
                <li><b>Distribution of personal data</b> - actions, that result in the disclosure of Personal Data to unidentified persons.</li>
                <li><b>Depersonalization of personal data</b> - actions, due to which it is impossible to determine the ownership of personal data by a specific subject of personal data.</li>
                <li><b>Account</b> - a set of data about the User that was provided during Registration and which are stored in the Platform database, in order to identify him and provide access to closed resources of the Platform.</li>
                <li><b>Operator</b> - a legal entity (SIA "Damask"), the state registration of which was carried out in accordance with the established procedure on the territory of the Republic of Latvia, which owns the Platform and processes Personal Data, and also determines the purposes and content of Personal Data processing</li>
                <li><b>Regulations</b> - the current internal document regulating the work of the Operator in the processing of Personal Data.</li>
              </ul>
            </li>
            <li>
              <h2 id="the-composition-of-the-users-personal-data">The composition of the User's personal data</h2>
              <ol>
                <li>
                  <p>Self-provided by the User during Registration:</p>
                  <ul>
                    <li>e-mail.</li>
                  </ul>
                </li>
                <li>
                  <p>Self-provided by the User after Authentication on the Site in the User profile section:</p>
                  <ul>
                    <li>IP - address,</li>
                    <li>cookie data,</li>
                    <li>Information about browser,</li>
                    <li>hardware and software specifications,</li>
                    <li>date and time of access to the Platform,</li>
                    <li>addresses of requested resources.</li>
                  </ul>
                </li>
                <li>
                  <p>Automatic collection of data from the User:</p>
                  <ul>
                    <li>e-mail.</li>
                  </ul>
                </li>
                <li><p>The personal data specified in paragraph 3 (of this document) are processed in order to identify Users, ensure the execution of the user agreement, provide the User with personalized services and content, and improve the quality of the service.</p></li>
                <li><p>The processing of personal data of Users is carried out with their consent. The User Registering on the Site in order to gain access to the services, thereby expresses his full consent in accordance with the Regulation of the European Parliament and of the Council No. 2016/679 on the protection of individuals regarding the processing of personal data and the free circulation of such data.</p></li>
              </ol>
            </li>
            <li>
              <h2 id="confidentiality-of-personal-data">Confidentiality of personal data</h2>
              <ol>
                <li><p>The information listed in Article 3 of this Regulation is confidential. The Operator ensures the confidentiality of personal data - is obliged to prevent their distribution without the consent of the User, or the presence of another legal basis.</p></li>
                <li><p>All confidentiality measures during the collection, processing and storage of the User's Personal Data may apply to both paper and hardware storages.</p></li>
                <li><p>The confidentiality regime of personal data is removed in cases of depersonalization or their publication in public sources (mass media, the Internet or various public state registries).</p></li>
              </ol>
            </li>
            <li>
              <h2 id="rights-and-responsibilities-of-the-operator">Rights and responsibilities of the Operator</h2>
              <ol>
                <li>
                  <p>The Operator has the right, without the consent of the Site User, to process his personal data in the following cases:</p>
                  <ol>
                    <li><p>federal law regulations that oblige the Operator to provide certain information about the Platform User;</p></li>
                    <li><p>obtaining statistical data;</p></li>
                    <li><p>protection of life, health or other vital interests of the User of personal data, if obtaining the consent of the User of personal data is impossible;</p></li>
                    <li><p>subject to publication in accordance with federal laws, including personal data of persons holding public positions, positions of the state civil service and personal data of candidates for state or municipal positions;</p></li>
                  </ol>
                </li>
                <li>
                  <p>In order to ensure the rights and freedoms of man and citizen, the Operator and his employees, when processing the User's personal data, must comply with the following general requirements:</p>
                  <ol>
                    <li>The Operator ensures the protection of the User's personal data from their unlawful use.</li>
                    <li>If the Operator, on the basis of an agreement, entrusts the processing of personal data to a third party, an essential condition of the agreement is the observance of the confidentiality and security of this data.</li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <h2 id="personal-data-user-rights">Personal data User rights</h2>
              <ol>
                <li>
                  <p>The subject of personal data has the right to receive the following information from the Operator:</p>
                  <ol>
                    <li><p>what personal data the Operator stores related to the respective subject;</p></li>
                    <li><p>how the Operator uses this data.</p></li>
                  </ol>
                </li>
                <li><p>The subject of personal data has the right to demand from the Operator the clarification of his personal data, their blocking or destruction, if the personal data is incomplete, outdated, inaccurate, illegally obtained or not necessary for the stated purpose of processing, as well as take measures provided by law to protect their rights.</p></li>
                <li><p>Information about the availability of personal data must be provided to the subject of personal data by the Operator in an accessible form, and they should not contain personal data related to other subjects of personal data.</p></li>
                <li><p>Access to your personal data is provided to the subject of personal data or his legal representative by the Operator when applying or upon receipt of a request from the subject of personal data or his legal representative.</p></li>
                <li><p>The request for the provision of personal data must contain the number of the main document proving the identity of the subject of personal data or his legal representative, information about the date of issue of the specified document and the authority that issued it, and a handwritten signature of the subject of personal data or his legal representative. The request can be sent in electronic form and signed with an electronic digital signature in accordance with the legislation of the Republic of Latvia.</p></li>
                <li><p>The subject of personal data has the right to appeal against the actions or inaction of the Operator to the authorized body for the protection of the rights of subjects of personal data.</p></li>
              </ol>
            </li>
            <li>
              <h2 id="personal-data-processing">Personal data processing</h2>
              <ol>
                <li><p>The processing of personal data is carried out by the Operator solely to achieve the goals defined by this Regulation.</p></li>
                <li><p>The processing of personal data by the Operator consists in obtaining, systematizing, accumulating, storing, clarifying (updating, changing), using, distributing, depersonalizing, blocking, destroying and protecting against unauthorized access.</p></li>
                <li><p>The processing of the User's personal data can only be accessed by the Operator's employees, whose job responsibilities are directly related to access and work with the User's personal data.</p></li>
                <li><p>In the event of a corresponding request from the subject of personal data, the Operator is obliged to make the necessary changes, destroy or block the relevant personal data upon the provision by the subject of personal data or his legal representative of information confirming that the personal data that relate to the relevant subject and processed by the Operator are incomplete , outdated, inaccurate, illegally obtained or not necessary for the stated purpose of processing. The Operator is obliged to notify the subject of personal data or his legal representative and third parties to whom the personal data of this subject were transferred about the changes made and the measures taken.</p></li>
              </ol>
            </li>
            <li>
              <h2 id="transfer-of-personal-data">Transfer of personal data</h2>
              <ol>
                <li><p>The transfer of personal data is carried out by the Operator only if it is necessary to fulfill the User Agreement or provide the User with certain services with the consent of the User.</p></li>
                <li><p>The transfer of personal data to state bodies is carried out within the framework of their powers in accordance with the applicable legislation of the Republic of Latvia.</p></li>
              </ol>
            </li>
            <li>
              <h2 id="storage-of-personal-data">Storage of personal data</h2>
              <ol>
                <li><p>Personal data will be stored in the database of the Operator.</p></li>
              </ol>
            </li>
            <li>
              <h2 id="protection-of-personal-data-of-users">Protection of personal data of Users</h2>
              <ol>
                <li><p>Information containing the User's personal data is subject to protection.</p></li>
                <li><p>The Operator is obliged, when processing Users' personal data, to take the necessary organizational and technical measures to protect personal data from unauthorized or accidental access to them, destruction, modification, blocking, copying, distribution of personal data, as well as from other illegal actions.</p></li>
                <li><p>The Operator undertakes the overall organization of the protection of personal data of Users.</p></li>
              </ol>
            </li>
            <li>
              <h2 id="responsibility-for-disclosing-information">Responsibility for disclosing information</h2>
              <ol>
                <li><p>Employees of the Operator who are guilty of violating the rules governing the receipt, processing and protection of personal data shall bear disciplinary, administrative, civil or criminal liability in accordance with the laws of the Republic of Latvia.</p></li>
              </ol>
            </li>
          </ol>
        </div>
      </div>
    </div><!-- end row -->
  </div>
  </section>
</template>
<script>
export default {
};
</script>